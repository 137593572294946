.home-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 0 1rem 0;
}

.body-div {
  width: 100%;
  padding: 0.75rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.SearchAndTags {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 0.5rem 0;
  padding-right: 1%;
}

.SpaceDiv {
  display: block;
  height: 100%;
  width: 15%;
  min-width: 12.5rem;
  margin-right: 1%;
  margin-left: 1%;
}

.SearchAndResults {
  width: 100%;
}

.SearchContainer {
  display: flex;
  width: 100%;
}

.search-tags{
  display: flex; 
  flex-wrap: wrap;
  align-self: flex-end;
  width: 40%;
}

.results-and-col-editor {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.results-container {
  font-family: Apercu-regular;
  color: #5B6770;
  margin-left: 0%;
  margin-top: 0.5rem;
  width: 100%;
}

.filter-and-table {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.filter-container{
  width: 15%;
  min-width: 12.5rem;
  margin-right: 1%;
  margin-left: 1%;
  height: 65vh;
}

.footer {
  background-color: #D9D9D9;
  font-size: 10px;
  font-family: Apercu;
  color: #5B6770;
  text-align: center;
  width: 100%;
  padding: 0.25rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

.TableContainer{
  width: 100%;
  align-self: flex-start;
  overflow: visible;
  padding-right: 1%;
}

.TableContainer::-webkit-scrollbar{
  display: none;
}

.map {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  font-family: Apercu-regular;
    font-style: normal;
    font-size: 16px;
    color: #5B6770;
}

.leaflet-container{
  width: 75vw;
  height: 45vh;
}

.loading-page {
  display: flex;
  width: 100%;
  min-height: 20rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Apercu-regular;
  color: #5B6770;
  font-size: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.cfa-tag {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 0 !important;
  margin-bottom: 1% !important;
}

.cfa-typography-variant-caption1 {
  font-size: 15px !important;
  height: fit-content !important;
}

.column-editor {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.pin-icon:hover {
  color: #B00027;
}

.download-icon:hover {
  color: #B00027;
}
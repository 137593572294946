.nav-container{
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 2.25rem;
}


.header-style{
    font-family: Apercu-bold;
    font-style: normal;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: #004f71; 
    width: 60vw;
}


.button{
    display: flex;
    gap: 25px;
}

.button-item{
    font-family: Apercu-medium;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #5b6770;
}
.search-container{
    height: 100%;
    width: 100%;
    max-width: 25rem;
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
}

.search-bar{
    background: #ffffff;
    border: 1px solid #a7acaf;
    border-radius: 10px;
    padding: 0.5rem 0.75rem;
    width: 100%;
}
